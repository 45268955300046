<template>
   <div class="app-container app-theme-white body-tabs-shadow">
        <div class="app-container dm-sans-font">
            <div class="d-flex">
                <div class="text-center mt-4">
                    <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/logo/web.png" alt="logo">
                </div>
            </div>
            <div class="flex justify-center mt-10">
                <div class="md:mt-16">
                    <div class="no-gutters row flex justify-center mt-10 mb-5">
                         <div class="theme-main-size font-semibold text-lg md:text-2xl text-blue-900 md:tracking-wide whitespac-nowrap ">{{ $t('emailVerificationMessage') }}</div>
                    </div> <br>
                    <div class="no-gutters row flex justify-center mt-10 mb-5" v-if="loading">
                        <svg class="animate-spin h-8 w-8 text-gray-400"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div> <br>
                    <div class="no-gutters row flex justify-center mb-5" v-if="success">
                        <i class="fade-in el-icon-circle-check h-8 w-8 text-success" style="font-size: 90px;"></i>
                    </div>
                    <br>
                    <div class="flex flex-col justify-center mt-3" v-if="error">
                        <span class="text-red-500 font-semibold text-xl text-center mb-5">{{ errorMessage }}</span> <br>
                        <button  style="margin: auto;" class="bg-green-500  px-3 py-2 rounded shadow-sm text-xl md:w-80">
                            <div class="flex justify-center items-center m-auto">
                                <span class="text-white">Go to login</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
     name: 'EmailVerification',
     data: function () {
        return {
           loading: false,
           error: false,
           success: false,
           errorMessage: '',
        }
     },
      mounted () {
       const code = this.$route.query.code
      const email = this.$route.query.email
      this.error = false
      this.loading = true
      this.errorMessage = ''
      if (code && email) {
          if (code !== '' && email !== '') {
         this.$store.dispatch('auth/EmailVerification', { email : email, code : code })
                    .then(() => {
                            this.success = true
                             setTimeout(() => {
                                    this.$router.replace({ name : 'login' })
                                }, 2000)
                    })
                    .catch(() => {
                        this.loading = false
                        this.error = true
                        this.errorMessage = this.$t('emailError')
                    })
      } else {
           this.loading = false
           this.error = true
           this.errorMessage = this.$t('emailError')
        }
      } else {
           this.loading = false
           this.error = true
           this.errorMessage = this.$t('emailError')
      }
      }
}
</script>

<style scoped>

</style>
